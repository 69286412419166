/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "./layout.styles";

export const SecTitle = css`
  background-color: ${colors.green};
  padding: 60px 0;

  .container {
    flex-direction: column;
  }

  p {
    margin-bottom: 8px;
    @media (min-width: 320px) and (max-width: 767px) {
      display: none;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 320px) and (max-width: 767px) {
      img {
        width: 90px;
      }
    }

    h2 {
      color: #fff;
      font-size: 64px;
      font-weight: 700;
      padding: 0 0 0 30px;
      font-family: "Montserrat", sans-serif;
      margin: 0;
      max-width: 400px;

      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 32px;
      }
    }
    .arrowRight {
      transform: rotate(180deg);
    }
    &.executivo {
      margin-bottom: 24px;
      @media (min-width: 320px) and (max-width: 767px) {
        margin-bottom: 0;
      }
      h2 {
        flex-basis: min-content;
        padding: 0 30px 0 0;
      }
    }

    &:nth-of-type(2) {
      flex-direction: column;
    }
  }
`;

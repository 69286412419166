/** @jsx jsx */
import React, { Fragment } from "react";
import { ExecutivoData } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from "@emotion/react";

import {
  secTitle,
  subtitle,
  secDesk,
  commonSection,
} from "../assets/styles/layout.styles";

import { secExecutivo } from "../assets/styles/Executivo.style";

const Executivo = ({ title }) => {
  return (
    <section css={[commonSection, secExecutivo]}>
      <Container>
        <ul>
        {ExecutivoData.map(({ name }) => (
            <li>{name}</li> 
          ))}      
        </ul>
      </Container>
    </section>
  );
};

export default Executivo;

/** @jsx jsx */
import React, { Fragment } from "react";
import { SectionImage } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from "@emotion/react";

import {
  secTitle,
  subtitle,
  secDesk,
  commonSection,
} from "../assets/styles/layout.styles";

import { SecTitle } from "../assets/styles/SectionTitle.styles";

const SectionTitle = ({ title }) => {
  const { img } = SectionImage;
  return (
    <section css={[commonSection, SecTitle]}>
      <Container>
        {title === "Conselho Executivo" ? (
          <Fragment>
            <div className="content executivo">
              <h2>{title}</h2>
              <img src={img} className="arrowRight" />
            </div>
            <div>
              <p>
                <strong>Idealizadores</strong> e <strong>responsáveis</strong>{" "}
                pela organização da OPMBR
              </p>
              <p>(Engenheiros da Turma 89 do ITA)</p>
            </div>
          </Fragment>
        ) : (
          <div className="content">
            <img src={img} />
            <h2>{title}</h2>
          </div>
        )}
      </Container>
    </section>
  );
};

export default SectionTitle;

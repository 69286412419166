import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import SectionTitle from "@/components/section-title";
import Academico from "@/components/academico";
import Executivo from "@/components/executivo";
import QuemSomos from "@/components/quemSomos";
import HeaderInner from "@/components/header-inner";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";

const Sobre = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Quem Somos">
          <HeaderInner />
          <QuemSomos />
          <SectionTitle title="Conselho Acadêmico" />
          <Academico />
          <SectionTitle title="Conselho Executivo" />
          <Executivo />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Sobre;

/** @jsx jsx */
import React, { Fragment } from "react";
import { ConselhoData } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { jsx } from "@emotion/react";

import {
  secTitle,
  commonSection,
} from "../assets/styles/layout.styles";

import { conselho, prof, desktop, mobile,} from "../assets/styles/Academico.styles";

import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination]);

const carouselOptions = {
  spaceBetween: 0,
  loop: false,
  autoplay: {delay: 2000,},
  slidesPerView: 1.3,
  pagination: {
    el: "#client-carousel-pagination",
    type: "bullets",
    clickable: true,
  },
  breakpoints: {
    0: {
      spaceBetween: 0,
      slidesPerView: 1.3,
    },
    576: {
      spaceBetween: 0,
      slidesPerView: 1.3,
    },
    768: {
      spaceBetween: 0,
      slidesPerView: 2,
    },
    992: {
      spaceBetween: 0,
      slidesPerView: 3,
    },
    1200: {
      spaceBetween: 0,
      slidesPerView: 4,
    },
  },
};

const Academico = () => {
  return (
    <section css={[commonSection, conselho]}>
      <Container>
        <Row css={desktop}>

         {ConselhoData.map(({ img, name, desc }, index) => (
            <Col lg={3} md={3} sm={12}>
              <div css={prof}>
                <figure>
                  <img src={img} alt="Professor" />
                </figure>
                <h5>{name}</h5>
                <p>{desc}</p>
              </div>
            </Col>
          ))}
        </Row>

        <Row css={mobile}>
            
          <Swiper {...carouselOptions}>
              {ConselhoData.map(({img, name, desc }, index) => (
                <SwiperSlide key={index}>
                   <Col lg={3} md={3} sm={12}>
                    <div css={prof}>
                      <figure>
                        <img src={img} alt="Professor" />
                      </figure>
                      <h5>{name}</h5>
                      <p>{desc}</p>
                    </div>
                  </Col>
                </SwiperSlide>
              ))}
              {/* <div
                className="swiper-pagination"
                id="client-carousel-pagination"
              ></div> */}
            </Swiper>
          </Row>
      </Container>
    </section>
  );
};

export default Academico;

/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "./layout.styles";

export const conselho = css`

@media (min-width: 320px) and (max-width: 767px) {
  padding: 32px 0 0;
  }

`

export const prof = css`
  flex-direction: column;
  margin-bottom: 32px;
  text-align: center;

  figure {
    border-radius: 50%;
    overflow: hidden;
    width: 169px;
    height: 169px;
    text-align: center;
    margin: 0 auto 24px;
    background: linear-gradient(121.34deg, #d2cebb 14.92%, #dfdbca 87.77%);
    display: flex;
    img {
      width: 100%;
    }
  }

  h5 {
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    line-height: 30px;
    color: ${colors.blue};
  }

  p {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.dark};
  }
`;

export const mobile = css`
  display: none;

  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
`;

export const desktop = css`

@media (min-width: 320px) and (max-width: 767px) {
  display: none;
}
`;

/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "./layout.styles";

export const abAgency = css`
  height: 100vh;
  padding: 80px 0 0;
  background-color: ${colors.gray};
  display: flex;
  align-items: center;

  h1 {
    font-size: 80px;
    line-height: 88px;
    text-transform: uppercase;

    @media (min-width: 320px) and (max-width: 767px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      display: none;
    }
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }

  figure {
    display: none;
    position: relative;
    @media (min-width: 320px) and (max-width: 991px) {
      display: block;
      img {
        width: 100%;
      }
      .whiteOverlay {
        position: absolute;
        width: 100%;
        bottom: -1px;


        background: -moz-linear-gradient(
          top,rgba(241, 242, 246, 0) 0%,rgba(241, 242, 246, 1) 100%
        );
        background: -webkit-linear-gradient(
          top,rgba(241, 242, 246, 0) 0%,rgba(241, 242, 246, 1) 100%
        ); 
        background: linear-gradient(
          to bottom,rgba(241, 242, 246, 0) 0%,rgba(241, 242, 246, 1) 100%
        );
        height: 100px;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 70px;
    height: auto;
    align-items: flex-start;
    padding-bottom: 24px;

    h1 {
      font-size: 48px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;

export const pr79 = css`
  padding-bottom: 80px;

  img {
    margin-bottom: 52px;
  }

  p {
    margin-bottom: 24px;
  }

  form {
    width: 90%;
    button {
      margin-top: 32px;
      padding: 28px 32px;
    }
  }

  input {
    padding: 2rem 0.75rem;
    border-color: ${colors.green};
    color: ${colors.dark};
    border-radius: 8px;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-ms-input-placeholder,
    &::-moz-placeholder {
      color: ${colors.dark};
      font-size: 18px;
    }
  }

  .professores_mobile {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 0px;
    .professores_mobile {
      display: block;
      width: 100%;
      margin-bottom: 0;
    }
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
    }
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
    p {
      font-size: 18px;
      line-height: 20px;
    }
    form {
      width: 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .professores_mobile {
      display: block;
    }
    img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    form {
      width: 100%;
    }
  }
`;

export const mb45 = css`
  margin-bottom: 45px;
  @media (min-width: 768px) and (max-width: 990px) {
    margin-bottom: 30px;
  }
`;

export const abImg = css`
  position: absolute;
  right: 30px;
  top: 0;
  z-index: 1;
  /* background-color: #ddd; */
  width: 55%;
  height: 100%;
  img {
    position: absolute;
    &.prof1 {
      bottom: 0;
      left: 0px;
      width: 90%;
      z-index: 1;
    }
  }

  /* &:nth-child(2) {
    position: relative;
    top: 30px;
    margin-top: 0;
    &:after {
      @media (min-width: 768px) and (max-width: 990px) {
        width: 350px;
        left: 15px;
      }
    }
  } */

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
  @media (min-width: 992px) and (max-width: 1366px) {
    img {
      /* &.prof1 {
        width: 66%;
      } */
      &.prof2 {
        right: 98px;
        width: 53%;
      }
      &.vetor1 {
        bottom: 184px;
        right: 36px;
      }
      &.vetor2 {
        right: 32px;
      }
    }
  }
`;

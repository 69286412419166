/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "./layout.styles";

export const secExecutivo = css`
  padding: 60px 0;

  ul{
    margin: 0;
    padding: 0;
    @media (min-width: 320px) and (max-width: 767px) {
      li{
        font-size: 18px;
      }
    }
  }

`;
